export default interface ProductInterface {
    id: number;
    name: string;
    description: string
    crafting_price?: number;
    material_price?: number;
    weigth_price?: number;
    price: number; 
    weight_unit?: number;
    created_at: Date;
    category: string;
    type_tags: string;
    stock: number;
    urlImg?: string;
}

export interface CartItem extends ProductInterface {
    quantity: number;
}

export enum CartOperations {
    ADD = 'add',
    REDUCE = 'reduce',
    DELETE = 'delete'
}
