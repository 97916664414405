import React from 'react';

function handleSubmit(event){
    event.preventDefault();
}

function onClose(event){
    event.preventDefault();
}

function handleChange(event){
    event.preventDefault();
}

const Config = () => {
    return <div className="page-content">
        <div className="container">
            <div className="row">
                <div className="column">
                    <h2>Configuración</h2>;

                    <div className="row">
                        <div className="column column-50">
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <label htmlFor="name">Porcentaje de ganancia</label>
                                    <input id="name" name="name" type="text" value="" onChange={handleChange} /> %

                                    <label htmlFor="weight">Límite de almacen</label>
                                    <input id="weight" name="weight" type="number" value="" onChange={handleChange} />

                                    <label htmlFor="description">Categorías por uso</label>
                                    <textarea id="description" name="description" value="" onChange={handleChange}></textarea>

                                    <label htmlFor="category">Categorías por tema</label>
                                    <input id="category" name="category" type="text" value="" onChange={handleChange} />

                                    <label htmlFor="price">Estimados</label>
                                    <input id="price" name="price" type="number" step="0.01" value="" onChange={handleChange} />

                                    <label htmlFor="inventory">Formato reporte</label>
                                    <input id="inventory" name="inventory" type="number" value="" onChange={handleChange} />

                                    <hr />

                                    <div className="float-right">
                                        <button disabled type="submit" className="button">Añadir producto</button>-
                                        <button disabled type="submit" className="button">Guardar cambios</button>-
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
};

export default Config;