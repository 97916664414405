import React, { useEffect, useState } from 'react';
import ProductFilterableList from '../components/product-filterable-list.tsx';  
import { catchError} from 'rxjs';
import { ProductService } from '../products/product.service.ts';
import { DB_KEYS } from '../config/db.keys.ts';

const _productService = new ProductService();

const Home = () => {
    // const ProductsPromise = useLoaderData() as Promise<ProductInterface>;
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const products$ = ProductService.serviceState.pipe(
          catchError(error => {
            console.error(error);
            return [{}];
          })
        )

        const productSubs = products$.subscribe(productData => {
            setProducts(productData);
        })

        _productService.getProducts(DB_KEYS.PRODUCTS);

        return ()=>{
          productSubs.unsubscribe();
        }
    
    }, [])
    
    return <>
        <main className="page-content">
            {
                Boolean(Math.abs(products.length)) &&
                <ProductFilterableList 
                    initialProducts={products}
                />   
            }
        </main>
    </>;
};

export default Home;