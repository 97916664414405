import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesConfig } from '../config/configRoutes.ts';

const NavMenu = () => {
    return (<>
        <nav className='main-menu'>
            <ul className='logo'>
                <Link to={RoutesConfig.home}>
                    <li>Quetz<sup>App</sup></li>
                </Link>
            </ul>
            <ul className='main-menu-links'>
                <li>
                    <Link to={RoutesConfig.home}>Home</Link>
                </li>
                <li>
                    <Link to={RoutesConfig.dashboard}>Reportes</Link>
                </li>
                <li>
                    <Link to={RoutesConfig.about}>About</Link>
                </li>
                <li>
                    <Link to={RoutesConfig.config}>⚙️</Link>
                </li>
            </ul>
        </nav>
    </>)
};

export default NavMenu;