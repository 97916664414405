import React, { useState } from 'react';
import ProductInterface from '../config/product.interface';

function ProductDetail({ product, onClose, onUpdate }: { product: ProductInterface, onClose: VoidFunction, onUpdate: CallableFunction }) {
    const [editedProduct, setEditedProduct] = useState(product)

    const handleChange = (e) => {
        setEditedProduct({ ...editedProduct, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onUpdate(editedProduct)
    }

    const handleBgClick = (event) => {
        event.stopPropagation();
        if (!event.target.className.includes('modal')) return;
        onClose();
    }

    return (
        <div className="modal" onClick={handleBgClick}>
            <div className="modal-content product-editable">
                <figure className='modal-content-image'>
                    <img src={`./img/${editedProduct.urlImg}`} alt={editedProduct.description} />
                    <h3>Edit Product</h3>
                </figure>
                <div className="product-editable-body">
                    <form onSubmit={handleSubmit}>
                        <fieldset>

                            <div className="product-editable-body-inputs">
                                <div className="input-group">
                                    <label htmlFor="name">Nombre:</label>
                                    <input id="name" name="name" type="text" value={editedProduct.name} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="weight_unit">Peso por unidad (gm):</label>
                                    <input id="weight_unit" name="weight_unit" type="number" min="0" step="0.01" value={editedProduct.weight_unit} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="crafting_price">Precio de mano de obra:</label>
                                    <input id="crafting_price" name="crafting_price" type="number" min="0" step="0.01" value={editedProduct.crafting_price} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="material_price">Precio de material:</label>
                                    <input id="material_price" name="material_price" type="number" step="0.01" value={editedProduct.material_price} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="category">Categoría:</label>
                                    <input id="category" name="category" type="text" value={editedProduct.category} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="type">Temática:</label>
                                    <input id="type" name="type" type="text" value={editedProduct.type_tags} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="price">Precio final:</label>
                                    <input id="price" name="price" type="number" min="0" step="0.1" value={editedProduct.price} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="stock">Inventorio:</label>
                                    <input id="stock" name="stock" type="number" min="0" step="1" value={editedProduct.stock} onChange={handleChange} />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="description">Descripción:</label>
                                    <textarea id="description" name="description" value={editedProduct.description} onChange={handleChange}></textarea>
                                </div>
                            </div>

                            <div className="product-editable-body-options">
                                <button type="submit" className="button">Guardar cambios</button>
                                <button onClick={onClose} className="button button-outline">Cancelar</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;