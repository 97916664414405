import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App.tsx';
import ErrorPage from "./routes/errorPage.tsx";
import About from './pages/About.tsx';
import Dashboards from './pages/Dashboards.tsx';
import Config from './pages/Config.tsx';
import Home from './pages/Home.tsx';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { ApiRoutesConfig, RoutesConfig, Domain } from './config/configRoutes.ts';


// export const productsLoader = async () => fetch(`${Domain.dev}/${ApiRoutesConfig.getProducts}`);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutesConfig.home,
        // loader: productsLoader,
        element: <Home/>,
      },
      {
        path: RoutesConfig.dashboard,
        element: <Dashboards />,
      },
      {
        path: RoutesConfig.about,
        element: <About />,
      },
      {
        path: RoutesConfig.config,
        element: <Config />,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
