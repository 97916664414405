import React, { ReactElement, useEffect, useState } from 'react';
import ProductInterface, { CartItem, CartOperations } from '../config/product.interface.ts';
import Cart from './cart.tsx';
import ProductDetail from './product-detail.tsx';
import { ExternalLink, Search } from 'lucide-react';

const ProductFilterableList = ({ initialProducts }): ReactElement<{ initialProducts: ProductInterface[] }> => {

    const [products, setProducts] = useState<ProductInterface[]>(initialProducts)
    const [searchTerm, setSearchTerm] = useState<string>("")

    // Detail view product
    const [selectedProduct, setSelectedProduct] = useState<ProductInterface | null>(null)
    
    // Cart products
    const [cart, setCart] = useState<CartItem[]>([])

    // Animation effect
    const [addedToCart, setAddedToCart] = useState(0);

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase()) || 
        product.type_tags.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const addToCart = (product: ProductInterface): void => {
        if (product.stock > 0) {
            setAddedToCart(product.id);            
            const updatedProducts = products.map(p =>
                p.id === product.id ? { ...p, stock: p.stock - 1 } : p
            )
            setProducts(updatedProducts)
            const existingItem = cart.find(item => item.id === product.id)
            if (existingItem) {
                setCart(cart.map(item =>
                    item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
                ))
            } else {
                setCart([...cart, { ...product, quantity: 1 }])
            }
        }
    }

    const handleCart = (id, qty, operation: CartOperations) => {
        const selProduct = products.find(item => item.id === id);
        let updateStock = 0;
        
        if(operation === CartOperations.ADD){
            updateStock = selProduct!.stock - 1;
        } else if (operation === CartOperations.REDUCE) {
            updateStock = selProduct!.stock + 1;
        } else if (operation === CartOperations.DELETE) {
            updateStock = selProduct!.stock;
        }

        setProducts(products
            .map(product => product.id === id ? { ...product, stock: updateStock } : product)
        )

        setCart(
            cart
                .map(item => item.id === id ? { ...item, quantity: qty } : item)
                .filter(item => item.quantity > 0)
        )
    }

    const handleOpenDetails = (product) => {
        setSelectedProduct(product);
    }

    useEffect(()=>{
        setProducts(initialProducts);
    }, [])

    useEffect(()=>{
        const body = document.querySelector('body');
        body!.style.overflowY = Boolean(selectedProduct) ? 'hidden' : 'scroll';  
    }, [selectedProduct])

    return <>
        <div className="home">
            <div className="home-inventario">
                <h3> Inventario </h3>
                <div className="row">
                    <div className="column">
                        <div className="searchbar">

                            <em>
                                <Search className="icon" size={15} /> Búsqueda:
                            </em>
                            <input
                                type="text"
                                placeholder="Escribe articulos..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="products-box">
                            {filteredProducts.map(product => (
                                <div key={product.id} className={`products-box-card ${addedToCart === product.id ? 'added-to-cart' : ''}`}>
                                    <header>
                                        <img src={`./img/${product.urlImg}`} alt={product.description} />
                                        <h4>{product.name}</h4>

                                        <div className="options">

                                            <button 
                                                className="button details-btn" 
                                                onClick={()=> handleOpenDetails(product)}
                                            >
                                                <span>
                                                    Detalles&nbsp;
                                                    {<ExternalLink className="icon" size={13} />}
                                                </span>
                                            </button>

                                            <button
                                                className="button button-outline cart-btn"
                                                onClick={() => addToCart(product)}
                                                disabled={product.stock === 0}
                                            >
                                                Añadir a carrito
                                            </button>
                                        </div>
                                    </header>
                                    <div className="products-box-card-body">
                                        <p>{product.description} <br></br>
                                            Quedan: {product.stock} pz.
                                        </p>

                                        <div className="tags">
                                            <span className="tags-category" onClick={() => setSearchTerm(product.category)}>
                                                {product.category}
                                            </span>
                                            <span className="tags-type" onClick={() => setSearchTerm(product.type_tags)}>
                                                {product.type_tags}
                                            </span>
                                        </div>

                                        <div className="values">
                                            <p className="values-weight">{product.weight_unit!}gm</p>
                                            <p className="values-price">${product.price}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/**
             * @todo update function to save into LocalStorage
             */}
            {selectedProduct && (
                <ProductDetail
                    product={selectedProduct}
                    onClose={() => setSelectedProduct(null)}
                    onUpdate={(updatedProduct) => {
                        setProducts(products.map(p => p.id === updatedProduct.id ? updatedProduct : p))
                        setSelectedProduct(null)
                    }}
                />
            )}

            <div className="home-carrito">
                <Cart 
                    cart={cart}
                    cartUpdate={handleCart} 
                />
            </div>
        </div>
    </>
}

export default ProductFilterableList;