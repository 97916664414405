import React, { useEffect } from 'react';

const About = () => {

    useEffect(()=>{

    }, []);

    return <div className="page-content">
        <h2>Acerca de</h2>;
    </div>
};

export default About;