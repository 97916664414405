import { MinusCircle, PlusCircle, ShoppingCart, XCircle } from 'lucide-react';
import React from 'react';
import { CartOperations } from '../config/product.interface.ts';

const Cart = ({cart, cartUpdate}) => {

    const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0)
    const total = subtotal + (subtotal *.16);

    const handleQuantity = (event, item) => {
        if(item.stock >= item.quantity) {
            cartUpdate(item.id, event.target.value)
        }
    }

    return <>
        <div className="cart">
            <h3> <ShoppingCart className="icon" /> Carrito de compras</h3>
            <div className="cart-articles">
                {cart.length === 0 ? (
                    <p>Tu carrito está vacío</p>
                ) : (
                    <div id="cart">
                        {cart.map((item, index) => (
                            <div key={index} className="row cart-product">
                                <div className="column column-75">
                                    <div className="cart-product-name">
                                        {item.name} 
                                        <button 
                                            className="svgBtn" 
                                            onClick={() => cartUpdate(item.id, 0, CartOperations.DELETE)}
                                        >
                                            {<XCircle size={12} color='#3a3a3a' />}
                                        </button>
                                    </div>

                                    <div className="cart-product-qty">
                                        <button 
                                            className="svgBtn" 
                                            onClick={() => cartUpdate(
                                                item.id, 
                                                item.quantity - 1, 
                                                CartOperations.REDUCE)}
                                        >
                                            {<MinusCircle size={20} />}
                                        </button>

                                        <input 
                                            disabled 
                                            id={item.id} 
                                            name={item.id} 
                                            type="number" 
                                            step="1" 
                                            min="0" 
                                            max={item.stock} 
                                            value={item.quantity} 
                                            onChange={e => handleQuantity(e, item)} 
                                        />
                                        
                                        <button 
                                            className="svgBtn" 
                                            disabled={item.stock <= item.quantity} 
                                            onClick={() => cartUpdate(
                                                item.id, 
                                                item.quantity + 1, 
                                                CartOperations.ADD)}
                                        >
                                            {<PlusCircle size={20} />} 
                                        </button>
                                    </div>
                                </div>
                                <div className="column column-25 text-right">
                                    ${(item.price * item.quantity).toFixed(2)}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="cart-totals">
                <div className="cart-totals-subtotal">
                    <strong>Subtotal</strong>
                    <span>${subtotal.toFixed(2)}</span>

                </div>
                <div className="cart-totals-total">
                    <div className="total-container">
                        <strong>Total</strong>
                        <span>${total.toFixed(2)}</span>
                    </div>
                </div>

            </div>
            <button className='checkout button'> Checkout </button>
        </div>
    </>
}

export default Cart;