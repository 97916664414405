import { BehaviorSubject } from "rxjs";
import ProductInterface from "../config/product.interface";
import mockedProducts from "../test-data/products-test.data.json";
import { DB_KEYS } from "../config/db.keys.ts";


export class ProductService {
        
    public static serviceState: BehaviorSubject<ProductInterface[]|any> = new BehaviorSubject(mockedProducts); 
    
    constructor(){
        this.getProducts(DB_KEYS.PRODUCTS);
    }

    getProducts(hashKey: string):void {
        try {
            const retreivedProducts = JSON.parse(localStorage.getItem(hashKey)!) ?? mockedProducts;
            ProductService.serviceState.next(Object.values(retreivedProducts));
        } catch (error) {
            ProductService.serviceState.next(mockedProducts);
        }
    }

    write(hashKey: string, data: ProductInterface): void {
        if(!hashKey || !data) {
            console.error("No se pudieron guardar localmente los datos");
            return;
        }
        localStorage.setItem(hashKey, JSON.stringify(data));
        console.log("Datos almacenados exitósamente");
    }

    setProducts(hashKey: string, data: ProductInterface):Promise<boolean> {
        return new Promise((resolve, reject)=> {
            if (!hashKey || !data) {
                console.error("No se pudieron guardar localmente los datos");
                reject("Datos incorrectos");
            }

            setTimeout(() => {
                this.write(hashKey, data);
                resolve(true);
            }, 320);
        })
    }

}