import React from 'react';
import { Link, useRouteError } from "react-router-dom";
import { RoutesConfig } from '../config/configRoutes.ts';

function ErrorPage() {
    const error = useRouteError() as { statusText?: string, message?: string };
    return (
        <>
            <div id="error-msg">
                <h1>Uy!😥</h1>
                <p>Disculpa, hubo un error.</p>
                <p>
                    <i>{error?.statusText || error?.message}</i>
                </p>
            </div>
            <nav>
                <Link to={RoutesConfig.home}>Go back</Link>
            </nav>
        </>
    );
}

export default ErrorPage;