const protocol = "http://";
const port = 3000;

export enum RoutesConfig {
    home = "/",
    dashboard = "/dashboard",
    about = "/about",
    config = "/config",
}

export enum ApiRoutesConfig {
    getProducts = '/data/products-test.data.json'
}

export enum Domain {
    dev = `${protocol}localhost:${port}`
}

