import React from 'react';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import './styles/App.scss';
import {Outlet, useLocation } from 'react-router-dom';
import NavMenu from './components/nav-menu.tsx';

function App() {
  const location = useLocation();

  return (
    <>
      <NavMenu/>

      <div className="layout">
        <TransitionGroup>
          <CSSTransition key={location.pathname} timeout={300} classNames="page">
            <Outlet />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
}

export default App;
